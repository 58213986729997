import React from 'react';
import classnames from 'classnames';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext } from '@msdyn365-commerce/core';
import { getIcon } from './get-icon';
import { IParsedAttribute, getAttributesLocale, parseAttributesMap } from '../../shared-utils/attributes';

export interface IAllergensIconGridProps {
    className?: string;
    context: ICoreContext;
    attributes: AttributeValue[] | undefined;
    type: 'contains' | 'contains-traces';
}

// @ts-expect-error
function sortParsedAttributes(a: IParsedAttribute, b: IParsedAttribute) {
    return a.name.localeCompare(b.name);
}

export const AllergensIconGrid = (props: IAllergensIconGridProps) => {
    const { className, context, attributes, type } = props;

    if (!attributes) {
        return null;
    }

    // @ts-expect-error
    const parsed = parseAttributesMap(context, attributes);
    const attributeLocale: any = getAttributesLocale(props.context.request.locale);
    /* const allergens = parsed.find(x => x.name === attributeLocale.groups.allergens)?.attributes?.sort(sortParsedAttributes);

    if (!allergens) {
        return null;
    }

    const contains = allergens.filter(x => x.TextValue === attributeLocale.labels.yes);
    const containsTraces = allergens.filter(x => x.TextValue === attributeLocale.labels.canContains); */

    const contains: any[] = [];
    const containsTraces: any[] = [];

    if (type === 'contains' && contains.length === 0 || type === 'contains-traces' && containsTraces.length === 0) {
        return null;
    }

    return (
        <>
            <h4 className='allergens-icon-grid__heading'>{type === 'contains' ? attributeLocale.labels.contains : attributeLocale.labels.canContains}</h4>
            <div className={classnames('allergens-icon-grid', className)}>
                <ul>
                    {(type === 'contains' ? contains : containsTraces).map(x => (
                        <li key={x.name.toLowerCase().trim()}>
                            <div className={`allergens-icon-grid__icon-wrapper`}>
                                {getIcon(x.name, props.context.request)}
                            </div>
                            <div className={`allergens-icon-grid__name`}>{x.name}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};
